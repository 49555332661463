/**
 * Get error message of form control for the given form control name.
 *
 * @param formControl Target of the error message.
 */
import { AbstractControl } from '@angular/forms';

export function getErrorMessage(formControl?: AbstractControl | null): string {
  if (formControl === undefined || formControl === null) {
    return '';
  }

  if (formControl.hasError('numberInvalid')) {
    return (formControl.errors as { numberInvalid: { errorMsg: string } }).numberInvalid.errorMsg;
  }

  if (formControl.hasError('positiveNumberInvalid')) {
    return (formControl.errors as { positiveNumberInvalid: { errorMsg: string } }).positiveNumberInvalid.errorMsg;
  }

  if (formControl.hasError('required')) {
    return 'Required';
  }

  if (formControl.hasError('matDatepickerFilter')) {
    return `The current date is not available`;
  }

  if (formControl.hasError('lessThanInvalid')) {
    const { lessThan, errorMessage } = (
      formControl.errors as {
        lessThanInvalid: { lessThan: number; errorMessage?: string };
      }
    ).lessThanInvalid;

    if (errorMessage !== undefined) {
      return errorMessage;
    }

    return `Value must be less than (${lessThan})`;
  }

  if (formControl.hasError('greaterThanInvalid')) {
    const { greaterThan, errorMessage } = (
      formControl.errors as {
        greaterThanInvalid: { greaterThan: number; errorMessage?: string };
      }
    ).greaterThanInvalid;

    if (errorMessage !== undefined) {
      return errorMessage;
    }

    return `Value must be greater than (${greaterThan})`;
  }

  if (formControl.hasError('quantitySumInvalid')) {
    return 'Domestic trade schedules summary differs from production schedules summary decreased by consumption schedules summary';
  }

  if (formControl.hasError('notInTheList')) {
    return (formControl.errors as { notInTheList: string }).notInTheList;
  }

  if (formControl.hasError('alreadyAssigned')) {
    return (formControl.errors as { alreadyAssigned: string }).alreadyAssigned;
  }

  if (formControl.hasError('maxlength')) {
    return `Value must contains less than ${
      (formControl.errors as { maxlength: { requiredLength: number } }).maxlength.requiredLength
    } characters`;
  }

  if (formControl.hasError('min')) {
    return 'The specified value is below the minimum value';
  }

  if (formControl.hasError('scheduleNegativeAfterTrade')) {
    return 'Schedule value cannot be less than 0 after trade.';
  }

  if (formControl.hasError('isBetweenInvalid')) {
    const { isBetweenInvalid } = formControl.errors as {
      isBetweenInvalid: { greaterThan: number; lessThan: number };
    };

    if (isBetweenInvalid.greaterThan !== undefined && isBetweenInvalid.lessThan !== undefined) {
      return `Value must between (${isBetweenInvalid.greaterThan}) and (${isBetweenInvalid.lessThan})`;
    }

    if (isBetweenInvalid.greaterThan) {
      return `Value must be greater than (${isBetweenInvalid.greaterThan})`;
    }

    if (isBetweenInvalid.lessThan) {
      return `Value must be less than (${isBetweenInvalid.lessThan})`;
    }
  }

  if (formControl.hasError('notFirstDayOfMonth')) {
    return `Selected date should be start of the month.`;
  }

  if (formControl.hasError('sameRule')) {
    return 'Duplicate rules are not allowed';
  }

  if (formControl.hasError('incorrectOperatorValue')) {
    return 'Min operator values must be smaller than Max operator value for the same product and side';
  }

  if (formControl.hasError('invalidPhoneNumber')) {
    return `The given phone number is incorrect`;
  }

  if (formControl.hasError('invalidParameterType')) {
    return (formControl.errors as { invalidParameterType: string }).invalidParameterType;
  }

  if (formControl.hasError('invalidParameterValue')) {
    return (formControl.errors as { invalidParameterValue: string }).invalidParameterValue;
  }

  if (formControl.hasError('email')) {
    return 'Value must be an e-mail address';
  }

  if (formControl.hasError('maxlength')) {
    const { requiredLength } = (formControl.errors as { maxLength: { requiredLength: number } }).maxLength;

    return `The maximum length is ${requiredLength}`;
  }

  if (formControl.hasError('uppercaseInvalid')) {
    return 'Only uppercase characters are allowed';
  }

  if (formControl.hasError('lowercaseInvalid')) {
    return 'Only lowercase characters are allowed';
  }

  if (formControl.hasError('letterNumberInvalid')) {
    return 'Only letters and numbers are allowed';
  }

  if (formControl.hasError('optionAutocompleteLengthInvalid')) {
    return 'The entered option is not available';
  }

  if (formControl.hasError('optionAutocompleteNameInvalid')) {
    return 'You must select the option to add it to the list';
  }

  if (formControl.hasError('blankStringInvalid')) {
    return 'Entering blank spaces only is not allowed';
  }

  if (formControl.hasError('isAfterNow')) {
    return (formControl.errors as { isAfterNow: { errorMsg: string } }).isAfterNow.errorMsg;
  }

  if (formControl.hasError('isDelayAfter')) {
    return (formControl.errors as { isDelayAfter: { errorMsg: string } }).isDelayAfter.errorMsg;
  }

  if (formControl.hasError('invalidTenantIdentifierCharacters')) {
    return 'The tenant identifier can only consist of letters, numbers and the characters _ and -';
  }

  return '';
}
