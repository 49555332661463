import { DestroyRef } from '@angular/core';
import Handsontable from 'handsontable';
import { debounceTime, Subject, tap } from 'rxjs';
import { roundFloat } from '.';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/**
 * Creates the column summation subject array by the given column indexes.
 *
 * @param columns The column indexes for which we want to create the subjects.
 * @param handsonTable - the handsontable instance
 * @param destroyRef - DestroyRef to destroy the created subjects
 * @param decimals - decimals of the quantity sum
 * @param auctionType - It can be `DAM` or `IDA`.
 */
export function createColumnSummationSubjects<T>(
  columns: T[],
  handsonTable: Handsontable,
  destroyRef: DestroyRef,
  decimals: number,
  auctionType: 'DAM' | 'IDA'
): Map<T, Subject<number | null>> {
  const subjectMap = new Map<T, Subject<number | null>>();

  columns.map(column => {
    const columnSubject = new Subject<number | null>().pipe(
      debounceTime(100),
      tap(value => {
        const newValue =
          value === null ? null : `${roundFloat(value / (auctionType === 'IDA' ? 4 : 1), decimals, true)} MWh`;

        if (columns.every(column => typeof column === 'number')) {
          handsonTable.setDataAtCell(0, column as number, newValue, 'customUpdate');
        } else {
          handsonTable.setDataAtRowProp(0, column as string, newValue, 'customUpdate');
        }
      }),
      takeUntilDestroyed(destroyRef)
    ) as Subject<number | null>;

    subjectMap.set(column, columnSubject);
  });

  return subjectMap;
}
